.FormPage {
    font-family: "canada-type-gibson";
    font-size: 20px;
    font-weight: 300;    
    color: #000;
    line-height: 30px;
    hyphens: auto;
}

.FormPage a, .FormPage select, .FormPage input, .FormPage abbr, .FormPage span, .FormPage div, .FormPage option {
    font-family: "canada-type-gibson";
    font-size: 20px;
    font-weight: 300;    
    color: #000;
}

.FormPage a, .FormPage h1, .FormPage h2, .FormPage h3 {
  color: #005995;
}
.FormPage .react-calendar__month-view__weekdays__weekday {
    font-family: "canada-type-gibson";
    font-size: 8px;
    font-weight: 300;    
}
.FormPage strong {
  font-weight: 500;
}

.FormPage h1, .FormPage h2, .FormPage h3, .FormPage h4, .FormPage h5 {
    font-family: "georgia","Bitstream Charter","URW Bookman L",serif;
    font-weight: 500;
    word-wrap: break-word;
}

.line {
  margin-top: 20px;
  background-color: #7a9558;
  height: 2px;
  width: 120px;
}

.FormPage h1 {
  margin-top: 30px;
  line-height: 41px;
  font-size: 36px;
  font-weight: 500;
}

.FormPage h2 {
  margin-top: 30px;
  line-height: 41px;
  font-size: 20px;
  font-weight: 300;
}
.FormPage label.radiolabel {
  margin-top: 0px;
  font-weight: 300;
  color: #000;
}
.FormPage label {
  margin-top: 30px;
  font-weight: 300;
}

.FormPage select.form-select {
  background-color: #F5F5F5;
}
.FormPage input {
  background-color: #F5F5F5;
}
.FormPage .ArrivalDay select {
  padding: 0.2em;
  background-color: #F5F5F5;
}
.FormPage .payment input {
  background: #005995;
  border: 0;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 0.5em;
  padding: 0.5em 2em;
}


.FormPage .payment input:disabled,
input[disabled]{
  background-color: #cccccc;
  color: #000;
}