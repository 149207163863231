.Header {
  font-weight: 500;
  background-color: #fff;
  margin: 0 -15px 40px -15px;
}
.Header img {
  height: 60px;
}
.Header hr {
  background-color: #565c62;
  height: 0.5em;
  margin: 0 -15px 40px -15px;
}
