.ReschedulePage {
    font-family: "canada-type-gibson";
    font-size: 20px;
    font-weight: 300;    
    color: #000;
}

.ReschedulePage a {
    font-family: "canada-type-gibson";
    font-size: 20px;
    font-weight: 300;    
    color: #000;
}

.ReschedulePage strong {
  font-weight: 500;
}

.ReschedulePage h1, .FormPage h2, .FormPage h3, .FormPage h4, .FormPage h5 {
    font-family: "georgia","Bitstream Charter","URW Bookman L",serif;
    font-weight: 500;
    line-height: 1.36em;
    word-wrap: break-word;
}

.ReschedulePage h1 {
  font-size: 40px;
  font-weight: 500;
}

.ReschedulePage h2 {
  font-size: 24px;
  font-weight: 500;
}